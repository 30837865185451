import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [0,2,8];

export const dictionary = {
		"/(public)": [~37,[13],[14]],
		"/(public)/about": [~38,[13],[14]],
		"/(admin)/admin": [~16,[2],[3]],
		"/(admin)/admin/audit": [~17,[2],[3]],
		"/(admin)/admin/clients": [~18,[2],[3]],
		"/(admin)/admin/entities/[id]": [~19,[2],[3]],
		"/(admin)/admin/entity_applications/[application_id=uuid]": [~20,[2],[3]],
		"/(admin)/admin/entity_applications/[application_id=uuid]/[ticker=superstate_fund]": [~21,[2],[3]],
		"/(admin)/admin/organizations": [22,[2],[3]],
		"/(admin)/admin/organizations/[id]": [~23,[2],[3]],
		"/(admin)/admin/workflows": [24,[2],[3]],
		"/(admin)/admin/workflows/eng": [~25,[2],[3]],
		"/(portal)/apply/[id=uuid]": [~31,[8,9]],
		"/(portal)/apply/[id=uuid]/[ticker=superstate_fund]": [~32,[8,9]],
		"/(public)/blog": [39,[13,15],[14]],
		"/(public)/blog/[slug]": [40,[13,15],[14]],
		"/(portal)/dashboard": [~33,[8]],
		"/(portal)/documents": [~34,[8,10]],
		"/(auth)/forgot_password": [~26,[4],[5]],
		"/(public)/platform": [~41,[13],[14]],
		"/(portal)/portfolio": [~35,[8,11]],
		"/(auth)/register": [~27,[4],[5,6]],
		"/(auth)/reset_password": [~28,[4],[5,7]],
		"/(portal)/settings": [~36,[8,12]],
		"/(auth)/signin": [~29,[4],[5]],
		"/(auth)/signout": [~30,[4],[5]],
		"/(public)/sstgbf": [42,[13],[14]],
		"/(public)/uscc": [~43,[13],[14]],
		"/(public)/ustb": [~44,[13],[14]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';